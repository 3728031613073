import React from "react"
import PageSectionHeader from "./PageSectionHeader"
import Faqs from "../molecules/Faqs"

const FaqsSection = ({faqs}) => {
  return (
    <div className="synkli-section--horizontal-space synkli-section--vertical-space">
      <PageSectionHeader
        subTitle={`FAQ’s`}
        title={`Frequently Asked Questions`}
      />
      <div className="mt-20">
        <Faqs faqs={faqs} />
      </div>
    </div>
  )
}

export default FaqsSection
