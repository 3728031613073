// Hero Section
// Hero Section
export const heroSection = {
  title: `Get in touch, <span class="text-[#B695F8]">Let us know</span> how we can help you`,
  description: `Whether you have a question, feedback, or need assistance, our team is here to help. Reach out to us through any of the following methods, and we'll get back to you as soon as possible.`,
}

// Contact form section
export const contactFromSection = {
    iconsImage:{
        path: "contact-page--form-icons",
        alt: "contact-page--form-icons"
    },
    description: `Fill out the contact form, and we will respond to your message promptly. We value your input and look forward to assisting you!`
}

// Contact Boxes Section
export const contactBoxesSection = {
    box1SubTitle: `Are you Ready?`,
    box1Title: `Start a new project`,
    box2SubTitle: `What are you waiting for?`,
    box2Title: `Let’s talk about work`,
    box2ButtonText: `Start Work`
}