import React from "react"

const HeroSection = ({
  title,
  titleEC,
  description,
  descriptionEC,
}) => {
  return (
    <div className="synkli-section--horizontal-space synkli-section--vertical-space">
      <h1
        className={`text-[#0A1E46] text-[30px] md:text-[40px] lg:text-[58px] leading-[1.2em] font-[700] max-w-[800px] ${titleEC}`}
        dangerouslySetInnerHTML={{ __html: title }}
      />
      {description && (
          <p
            className={`text-[#0A1E46] text-[18px] lg:text-[20px] leading-[1.4em] font-[400] max-w-[800px] mt-8 ${descriptionEC}`}
          >
            {description}
          </p>
        )}
    </div>
  )
}

export default HeroSection
