import { graphql, useStaticQuery } from "gatsby"

const ImageUrlRenderer = ({ img }) => {

  const data = useStaticQuery(graphql`
    query {
      allFile(filter: { extension: { regex: "/(png|jpe?g|gif|svg)/" } }) {
        nodes {
          name
          childImageSharp {
            gatsbyImageData
          }
          publicURL
        }
      }
    }
  `)

  const imageNode = data.allFile.nodes.find(node => node.name === img)
  if(imageNode){
    return imageNode.publicURL
  }
}

export default ImageUrlRenderer
