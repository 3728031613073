// Keys Features
import React from "react"
import { faRocket } from "@fortawesome/free-solid-svg-icons"
import { faHeart } from "@fortawesome/free-solid-svg-icons"
import { faSun } from "@fortawesome/free-solid-svg-icons"
import CTASectionWithImage from "../components/molecules/CTASectionWithImage"

// Hero Section
export const heroSection = {
  heroTitleFirstRowText: `Accounting Software `,
  heroTitleSecondRowText: `For Businesses & `,
  heroTitleThirdRowText: `Accountants.`,
  brushImage: {
    path: "heading-below-brushline",
    alt: "heading-below-brushline",
  },
  description: `Introducing a powerful tool to streamline your business and accounting management. Synkli, a user-friendly accounting software, is designed to meet the needs of business owners and accounting firms.`,
  btns: [
    {
      to: "https://partners.synkli.com.au/signup/",
      href: "https://partners.synkli.com.au/signup/",
      text: "Get started",
      ec: "primary-btn",
    },
    {
      to: "/features/",
      href: "",
      text: "Learn More",
      ec: "primary-transparent-btn",
    },
  ],
}

// Hero Animation Section
export const HeroSectionAnimationItems = [
  {
    name: "speed1",
    items: [
      {
        peopleEC: "set1a",
        personEC: "speed1",
        imageTop: {
          path: "home-herosection--animated-image-1",
          alt: "home-herosection--animated-image-1",
        },
        imageBottom: {
          path: "home-herosection--animated-image-2",
          alt: "home-herosection--animated-image-2",
        },
      },
      {
        peopleEC: "set1b",
        personEC: "speed1",
        imageTop: {
          path: "home-herosection--animated-image-3",
          alt: "home-herosection--animated-image-3",
        },
        imageBottom: {
          path: "home-herosection--animated-image-4",
          alt: "home-herosection--animated-image-4",
        },
      },
      {
        peopleEC: "set1c",
        personEC: "speed1",
        imageTop: {
          path: "home-herosection--animated-image-5",
          alt: "home-herosection--animated-image-5",
        },
        imageBottom: {
          path: "home-herosection--animated-image-6",
          alt: "home-herosection--animated-image-6",
        },
      },
      {
        peopleEC: "set1d",
        personEC: "speed1",
        imageTop: {
          path: "home-herosection--animated-image-7",
          alt: "home-herosection--animated-image-7",
        },
        imageBottom: {
          path: "home-herosection--animated-image-8",
          alt: "home-herosection--animated-image-8",
        },
      },
      {
        peopleEC: "set1e",
        personEC: "speed1",
        imageTop: {
          path: "home-herosection--animated-image-9",
          alt: "home-herosection--animated-image-9",
        },
        imageBottom: {
          path: "home-herosection--animated-image-10",
          alt: "home-herosection--animated-image-10",
        },
      },
      {
        peopleEC: "set1f",
        personEC: "speed1",
        imageTop: {
          path: "home-herosection--animated-image-11",
          alt: "home-herosection--animated-image-11",
        },
        imageBottom: {
          path: "home-herosection--animated-image-12",
          alt: "home-herosection--animated-image-12",
        },
      },
    ],
  },
  {
    name: "speed2",
    items: [
      {
        peopleEC: "set4a",
        personEC: "speed2",
        imageTop: {
          path: "home-herosection--animated-image-13",
          alt: "home-herosection--animated-image-13",
        },
        imageBottom: {
          path: "home-herosection--animated-image-1",
          alt: "home-herosection--animated-image-1",
        },
      },
      {
        peopleEC: "set4b",
        personEC: "speed2",
        imageTop: {
          path: "home-herosection--animated-image-1",
          alt: "home-herosection--animated-image-1",
        },
        imageBottom: {
          path: "home-herosection--animated-image-2",
          alt: "home-herosection--animated-image-2",
        },
      },
      {
        peopleEC: "set4c",
        personEC: "speed2",
        imageTop: {
          path: "home-herosection--animated-image-3",
          alt: "home-herosection--animated-image-3",
        },
        imageBottom: {
          path: "home-herosection--animated-image-4",
          alt: "home-herosection--animated-image-4",
        },
      },
    ],
  },
  {
    name: "speed3",
    items: [
      {
        peopleEC: "set3a",
        personEC: "speed3",
        imageTop: {
          path: "home-herosection--animated-image-5",
          alt: "home-herosection--animated-image-5",
        },
        imageBottom: {
          path: "home-herosection--animated-image-12",
          alt: "home-herosection--animated-image-12",
        },
      },
      {
        peopleEC: "set3b",
        personEC: "speed3",
        imageTop: {
          path: "home-herosection--animated-image-13",
          alt: "home-herosection--animated-image-13",
        },
        imageBottom: {
          path: "home-herosection--animated-image-1",
          alt: "home-herosection--animated-image-1",
        },
      },
      {
        peopleEC: "set3c",
        personEC: "speed3",
        imageTop: {
          path: "home-herosection--animated-image-2",
          alt: "home-herosection--animated-image-2",
        },
        imageBottom: {
          path: "home-herosection--animated-image-3",
          alt: "home-herosection--animated-image-3",
        },
      },
    ],
  },
]

// HeroSectionAnimationAfterWidget Section
export const heroSectionAnimationAfterWidget = {
  image: {
    path: `homepage--widget`,
    alt: `homepage--widget`,
  },
}

// Pricing Section
export const pricingSection = {
  subTitle: `Pricing`,
  title: `Pricing Plans`,
  description: `Simplify Your Choices, Clear Pricing Plans Tailored to Your Needs`,
  percentageOffText: `<span className="text-[#B695F8]">20%</span> OFF ALL YEAR`,
  pricingArrowIcon: {
    path: `home-pricing--arrow-icon`,
    alt: `home-pricing--arrow-icon`,
  },
  plans: [
    {
      tagLine: `Easy Individual`,
      cardIcon: faRocket,
      title: `Salary Plan`,
      description: `Best for small businesses, startups with userbase in thousands.`,
      monthlyPrice: `9.99`,
      yearlyPrice: `95.99`,
      btn: {
        to: `https://partners.synkli.com.au/signup/`,
        href: `https://partners.synkli.com.au/signup/`,
        ec: ``,
        text: `Get Started`,
      },
      featres: [
        { text: "Track income" },
        { text: "Income & expenses" },
        { text: "Analytics" },
        { text: "Product Support" },
        { text: "Product" },
        { text: "Support" },
        { text: "Access for all tools" },
      ],
      featresPlus: [
        { text: "Task Tracking" },
        { text: "Basic Project Management" },
      ],
      monthly_addons:[
        { text: `Plus $5 - With Trip` }
      ],
      annual_addons:[
        { text: `Plus $48 - With Trip` }
      ],
    },
    {
      tagLine: `Recommended for you`,
      cardIcon: faHeart,
      title: `Business Plan`,
      description: `Best for small businesses, startups with userbase in thousands.`,
      monthlyPrice: `9.99`,
      yearlyPrice: `95.99`,
      btn: {
        to: `https://partners.synkli.com.au/signup/`,
        href: `https://partners.synkli.com.au/signup/`,
        ec: ``,
        text: `Get Started`,
      },
      featres: [
        { text: "Track income" },
        { text: "Income & expenses" },
        { text: "Analytics" },
        { text: "Product Support" },
        { text: "Support" },
        { text: "Access for all tools" },
        { text: "Single user Access" },
      ],
      featresPlus: [
        { text: "Task Tracking" },
        { text: "Basic Project Management" },
      ],
      monthly_addons:[
        { text: `Plus $5 - With Trip` }
      ],
      annual_addons:[
        { text: `Plus $48 - With Trip` }
      ],
    },
    {
      tagLine: `For Larger Tearms`,
      cardIcon: faSun,
      title: `Rental Plan`,
      description: `Best for small businesses, startups with userbase in thousands.`,
      monthlyPrice: `14.99`,
      yearlyPrice: `143.99`,
      btn: {
        to: `https://partners.synkli.com.au/signup/`,
        href: `https://partners.synkli.com.au/signup/`,
        ec: ``,
        text: `Get Started`,
      },
      featres: [
        { text: "Track income" },
        { text: "Income & expenses" },
        { text: "Analytics" },
        { text: "Product Support" },
        { text: "Product" },
        { text: "Support" },
        { text: "Access for all tools" },
      ],
      featresPlus: [
        { text: "Task Tracking" },
        { text: "Basic Project Management" },
      ],
      monthly_addons:[
        { text: `Plus $6 - Rental Samurai with 3 Properties`},
        { text: `Plus $15 - Property Boss with 7 Properties`},
        { text: `Plus $30 - Estate Elite with 15 Properties`},
      ],
      annual_addons:[
        { text: `Plus $57 - Rental Samurai with 3 Properties` },
        { text: `Plus $144 - Property Boss with 7 Properties` },
        { text: `Plus $288 - Estate Elite with 15 Properties` },
      ],
    },
  ],
}

// Key Feautures Section
export const keyFeaturesSection = {
  subTitle: `FEATURES`,
  title: `Key Features: The Ultimate Accounting Toolkit`,
  tabs: [
    {
      id: "tab1",
      label: "All-in-One Data Hub",
      content: (
        <CTASectionWithImage
          componentEC={"flex-col-reverse md:flex-row-reverse"}
          title={"All-in-One Data Hub"}
          description={`Businesses and accountants can keep all their financial data in just one place. No more digging through files or multiple systems—everything you need is on your Synkli account. This allows you to securely access and manage your data from anywhere, streamlining your accounting process and improving productivity.`}
          buttonn={{
            to: "/features/",
            href: "#",
            text: "Learn More",
            ec: "",
          }}
          image={{
            path: "home-keyfeatures--workspace",
            alt: "home-keyfeatures--workspace",
          }}
        />
      ),
    },
    {
      id: "tab2",
      label: "Online Signature",
      content: (
        <CTASectionWithImage
          componentEC={"flex-col-reverse md:flex-row-reverse"}
          title={"Online Signature"}
          description={`Say goodbye to paper trails with Synkli's online signature feature. Sign documents online quickly and securely, saving you time and effort. Our digital signature feature ensures your documents are legally binding and safely stored, making document management more efficient.`}
          buttonn={{
            to: "/features/",
            href: "#",
            text: "Learn More",
            ec: "",
          }}
          image={{
            path: "feature--online-signature",
            alt: "feature--online-signature",
          }}
        />
      ),
    },
    {
      id: "tab3",
      label: "Easy communication feature",
      content: (
        <CTASectionWithImage
          componentEC={"flex-col-reverse md:flex-row-reverse"}
          title={"Easy communication feature"}
          description={`Efficiently communicate with your team members and accountants through our integrated chatbox. Share updates, discuss tasks, and collaborate seamlessly within the platform. Instant messaging ensures quick responses and keeps everyone aligned, enhancing productivity and teamwork.`}
          buttonn={{
            to: "/features/",
            href: "#",
            text: "Learn More",
            ec: "",
          }}
          image={{
            path: "feature--easy-communication-feature",
            alt: "feature--easy-communication-feature",
          }}
        />
      ),
    },
    {
      id: "tab4",
      label: "Expense and income management",
      content: (
        <CTASectionWithImage
          componentEC={"flex-col-reverse md:flex-row-reverse"}
          title={"Expense and income management"}
          description={`Keep track of your business expenses and income effortlessly. Our intuitive interface helps you monitor and manage your cash flow with ease. Generate detailed reports, set budgets, and gain insights into your financial health, ensuring better financial planning.`}
          buttonn={{
            to: "/features/",
            href: "#",
            text: "Learn More",
            ec: "",
          }}
          image={{
            path: "feature--expense-and-income-management",
            alt: "feature--expense-and-income-management",
          }}
        />
      ),
    },
    {
      id: "tab5",
      label: "Mileage Tracker",
      content: (
        <CTASectionWithImage
          componentEC={"flex-col-reverse md:flex-row-reverse"}
          title={"Mileage Tracker"}
          description={`Days of manually tracking your trips are over with our mileage tracker feature. You can automatically track your mileage for business and personal trips. Log and report your travel easily to ensure you get accurate deductions claims.`}
          buttonn={{
            to: "/features/",
            href: "#",
            text: "Learn More",
            ec: "",
          }}
          image={{
            path: "feature--mileage-tracker",
            alt: "feature--mileage-tracker",
          }}
        />
      ),
    },
    {
      id: "tab6",
      label: "Team Management",
      content: (
        <CTASectionWithImage
          componentEC={"flex-col-reverse md:flex-row-reverse"}
          title={"Team Management"}
          description={`Synkli has a feature that lets you have all your team members in a place. You can assign roles, track progress, and ensure everyone is on the same page. This improves collaboration and productivity by managing your team's workload and communication in one centralised platform.`}
          buttonn={{
            to: "/features/",
            href: "#",
            text: "Learn More",
            ec: "",
          }}
          image={{
            path: "feature--team-managment",
            alt: "feature--team-managment",
          }}
        />
      ),
    },
    {
      id: "tab7",
      label: "Efficient Task Management",
      content: (
        <CTASectionWithImage
          componentEC={"flex-col-reverse md:flex-row-reverse"}
          title={"Efficient Task Management"}
          description={`Stay on top of your to-do list with our task management feature. Create, assign, and track tasks for your team members to ensure nothing falls through the cracks. Prioritise and manage your clients ' deadlines effortlessly, keeping your team organised and projects on track.`}
          buttonn={{
            to: "/features/",
            href: "#",
            text: "Learn More",
            ec: "",
          }}
          image={{
            path: "feature--task-management",
            alt: "feature--task-management",
          }}
        />
      ),
    },
  ],
}

// Use Cases Section
export const useCasesSection = {
  subTitle: `Use Cases`,
  title: `End Users of Synkli`,
  useCases: [
    {
      title: `Businesses and individuals`,
      description: `Synkli is tailored to meet the specific needs of business owners and individuals by simplifying bookkeeping processes and providing real-time financial insights. By automating repetitive tasks and centralising financial data, businesses can optimise their operations, promptly make informed and data-driven decisions, and effectively maintain financial health.`,
      image: {
        path: `end-users-of-synkli--businesses-and-individuals`,
        alt: `end-users-of-synkli--businesses-and-individuals`,
      },
    },
    {
      title: `Accountants and bookkeepers`,
      description: `Our software equips accountants with advanced tools for precise financial management and client collaboration. With features such as detailed reporting, team management, and secure data sharing, accountants can enhance accuracy and streamline workflow efficiencies. Synkli provides accounting solutions that meet the diverse needs of its clients, ultimately fostering long-term success and trust.`,
      image: {
        path: `end-users-of-synkli--accountants-and-bookkeepers`,
        alt: `end-users-of -synkli--accountants-and-bookkeepers`,
      },
    },
  ],
}

// Testimonials Section
export const testimonialSection = {
  title: `What our clients are saying`,
  description: `Clients are best positioned to share their opinions and testimonials with others. Hear first-hand testimonies from satisfied clients on how we have improved their accounting and financial lives with designs made for them. Get to know how we transform businesses and accountants' style of management.`,
}

// MobileAppLinks Section
export const mobileAppLinksSection = {
  title: `Maximising your tax returns with easy deduction claims`,
  description: `Download our mobile app to ensure you have accurate record keeping and an increase in your tax refunds. With Synkli, you can explore many accounting tools and features.`,
}

// Happy Customer Section
export const happyCustomerSection = {
  subTitle: `Happy Customer`,
  mainTitle: `A Day in The Life of a Happy Client`,
  title: `Using just software that helps with multiple functions is amazing`,
  description: `“Synkli has been a game changer as it helps our accounting firm manage our clients' accounting needs simultaneously. We now know how to make businesses that work with us organise their financial summaries, simplifying workflows for all parties involved.”`,
}

// Faqs Section
export const faqs = [
  {
    question:
      "Is the app the same for accountants and businesses, or are they different?",
    answer:
      `There are different apps for businesses and accountants. Therefore, the one you download should meet your needs as a business owner or an accountant.`
  },
  {
    question:
      "How can Synkli benefit my business?",
    answer:
      `Businesses always need help dealing with the accounting aspect. However, with Synkli, you can easily record your income and expenses. Another benefit is you won’t miss out on any business deduction for which you are eligible. You can also scan your receipt and save it on the app. Lastly, you have access to your accountants on Synkli.`
  },
  {
    question:
      "Does Synkli support integration with other accounting tools or software?",
    answer:
      `Yes. Accountants can sync their data with XERO and other platforms, like spreadsheets. They can import and export data from Synkli to Xero or vice versa. The syncing process is easy.`
  },
  {
    question:
      "Can I use Synkli for free?",
    answer:
      `The app has a free and premium versions. However, to enjoy all of it’s amazing benefits, you will need a subscribe. Luckily, Synkli is offered with 30 days free trial to both accountant and business/individuals so you can explore all these features and see how they improve your workload.`
  },
  {
    question:
      "Can I access my data offline?",
    answer:
      `No, you can't access your data without an internet connection. You need to be online to use Synkli.`
  },
  {
    question:
      "Does Synkli support two-factor authentication?",
    answer:
      `Yes, Synkli requires all users to have two-factor authentication (2FA) enabled. This means you need to confirm your identity in two ways, like using your password and a code sent to your phone, to log in.`
  },
  {
    question:
      "Can I use the website instead of the app?",
    answer:
      `The website is only for accountants right now. If you're a business or an individual, you need to use the mobile app.`
  },
  {
    question:
      "What if I’m an accountant and a business owner, must I use the two?",
    answer:
      `Yes, you will need to create two separate accounts. One account will be on the website for your accountant role, and the other will be on the mobile app for your business role.`
  },
  {
    question:
      "Is the mileage tracker feature for businesses alone?",
    answer:
      `No, the mileage tracker can be used by both businesses and individuals. It's available to everyone on the app.`
  },
  {
    question:
      "Can bookkeepers use the app too?",
    answer:
      `Yes, bookkeepers can use the same app that accountants use. They don't need a separate app.`
  },
  {
    question:
      "How does it help me claim my deductions?",
    answer:
      `The app has a "scan and go" feature. You can quickly scan your receipts or invoices with your phone. The app will automatically read the information using OCR (Optical Character Recognition) technology. It then helps you organise and store these records, making it easier to track and claim deductions.`
  },
  {
    question:
      "What kind of training or support is available to help our team get up to speed with using the software?",
    answer:
      `Synkli team offers 1 on 1 session to all the accountants to make the app ready to use in the firm. Once an accountant purchases a subscription, one of our team members will be in touch with you to carry forward the process.`
  },
  // More questions...
]

// BusinessModelsWithForm Section
export const businessModelsWithFormSection = {
  title: `Synkli – Your Ultimate Business Management Partner`,
  description: `Elevate your business with Synkli, your comprehensive business management solution. Streamline operations, enhance efficiency, and drive growth with our intuitive software.`,
  formSubmitButtonText: `Submit`,
}

// Blog section
export const blogsSection = {
  subTitle: `Magazine`,
  title: `Lets turn your idea into a scalable business`,
}
