import React from "react"
import { Disclosure } from "@headlessui/react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus } from "@fortawesome/free-solid-svg-icons"
import { faMinus } from "@fortawesome/free-solid-svg-icons"
import { Helmet } from "react-helmet"
import ImageUrlRenderer from "../atoms/ImageUrlRenderer"

const Faqs = ({ faqs, questionEC, answerEC, iconEC }) => {
  const ImageURL = ImageUrlRenderer({ img: "faq-plus-icon-svg" })

  return (
    <>
      <Helmet>
        <style>
          {`
        .faqs-section-cursor * {
          cursor: url(${ImageURL}) 12 12, auto;
        }
        
      `}
        </style>
      </Helmet>

      <div className="mx-auto divide-y divide-gray-900/10 faqs-section-cursor">
        <dl className="space-y-6 border-b border-gray-300 divide-y divide-gray-900/10">
          {faqs &&
            faqs.map(faq => (
              <Disclosure
                as="div"
                key={faq.question}
                className="border-t border-gray-300 !mt-0"
              >
                {({ open }) => (
                  <div className="flex flex-col justify-between">
                    <dt>
                      <Disclosure.Button className="flex w-full items-start justify-between text-left pt-6 pb-6">
                        <span
                          className={
                            "text-[18px] lg:text-[22px] text-[#202020] font-[500] leading-[1.5em] " +
                            questionEC
                          }
                          dangerouslySetInnerHTML={{ __html: faq.question }}
                        />
                        <span
                          className={
                            "ml-6 flex h-7 items-center border-2 border-[#101828] rounded-[50%] text-[20px] min-w-[35px] min-h-[35px] text-center flex items-center justify-center " +
                            iconEC
                          }
                        >
                          {open ? (
                            <FontAwesomeIcon icon={faMinus} />
                          ) : (
                            <FontAwesomeIcon icon={faPlus} />
                          )}
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="pb-5">
                      <p
                        className={
                          "text-[18px] leading-[1.5em] text-[#2A2A2A] " +
                          answerEC
                        }
                        dangerouslySetInnerHTML={{ __html: faq.answer }}
                      />
                    </Disclosure.Panel>
                  </div>
                )}
              </Disclosure>
            ))}
        </dl>
      </div>
    </>
  )
}

export default Faqs
