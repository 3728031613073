import React from "react"
import ContactForm from "../../common/ContactForm"
import ImageRenderer from "../../atoms/ImageRenderer"

const ContactFormSection = ({ iconsImage, description, descriptionEC }) => {
  return (
    <div className="synkli-section--horizontal-space">
      <div className="flex flex-col-reverse md:flex-row items-stretch gap-8 shadow-md shadow-[#001F1F24]-500 border border-[#001F1F08] rounded-3xl">
        <div className="flex-[2] px-8 md:px-12 py-10 md:py-16">
          <h2 className="text-[24px] md:text-[32px] leading-[1.2em] font-[600] text-[#2A2A2A] mb-8">
            Contact Us
          </h2>
          <ContactForm
            formType={"contactform"}
            labelEC={`!text-[#000000] font-[500]`}
            fieldEC={`!border-[#979797] !text-[#0a1e46]`}
            dropDownbtnEC={`!border-[#979797] !text-[#9ca3b7] font-[400]`}
            dropDownIconEC={`!text-[#979797]`}
            buttonText={`Get Help`}
            buttonEC={`!bg-[#0A1E46] !text-white hover:!bg-transparent hover:!text-[#0A1E46] hover:border-[#0A1E46]`}
          />
        </div>
        <div className="flex-[1] bg-[#0A1E46] rounded-[20px] px-6 md:px-8 pt-12 md:pt-20 pb-10">
          <div className="max-w-[150px] md:max-w-[300px] mx-auto mb-16">
            <ImageRenderer img={iconsImage.path} alt={iconsImage.alt} />
          </div>
          {description && (
            <p
              className={`text-white text-center text-[18px] lg:text-[20px] leading-[1.4em] font-[300] max-w-[800px] mt-8 ${descriptionEC}`}
            >
              {description}
            </p>
          )}
        </div>
      </div>
    </div>
  )
}

export default ContactFormSection
